import React from "react";
import tw from "twin.macro";

const Container = tw.div`bg-primary-500 text-gray-100 pt-4 pb-4`
const Content = tw.div`max-w-screen-xl mx-auto`;

const Row = tw.div`flex items-center justify-center flex-col`


const LinksContainer = tw.div`font-medium justify-center items-center flex-col sm:flex-row`
const Link = tw.a`border-b-2 border-transparent hocus:text-gray-300 hocus:border-gray-300 pb-1 transition duration-300 mt-2 mx-4`;


const CopyrightText = tw.p`text-center mt-5 font-medium tracking-wide text-sm text-gray-600`
const Footer = () => {
  return (
    <Container>
      <Content style={{paddingBottom: '1rem'}}>
        <Row>
          <LinksContainer>
            <Link href="/">Home</Link>
            <Link href="https://blog.uclusion.com">Blog</Link>
            <Link href="/aboutus">About Us</Link>
            <Link href="mailto:support@uclusion.com">Contact Us</Link>
          </LinksContainer>
          <CopyrightText>
            &copy; Copyright 2024, Uclusion Inc. All Rights Reserved.
          </CopyrightText>
        </Row>
      </Content>
    </Container>
  );
};

export default Footer;
