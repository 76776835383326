import React, {useState} from "react";
import tw from "twin.macro";
import "./bounce.css";
import BelowTheFoldDemoTeaser from "./BelowTheFoldDemoTeaser";
import SingleTeaser from "./SingleTeaser";
import CustomerTeaser from "./CustomerTeaser";
const Container = tw.div`pt-20 w-full flex justify-center items-center`;
const TextContent = tw.div`text-center md:text-left mr-5 ml-5`;
const Heading = tw.div`font-black text-left text-4xl lg:text-6xl text-center leading-tight tracking-wide`;
const CTA = tw.div`text-center font-normal text-xl`;
const HeadingHighlight = tw.span`text-primary-500 whitespace-nowrap`;

const AboveTheFold = (properties) => {
    const { isMobile } = properties;
    const [visiblePage, setVisiblePage] = useState('single');

    return (
        <Container>
            <TextContent>
                <Heading>
                    Task management for <HeadingHighlight>complex making</HeadingHighlight>.
                </Heading>
                <CTA style={{fontSize: isMobile ? '1rem' : undefined, paddingTop: isMobile ? '2rem': '1rem',
                    marginLeft: isMobile ? '1rem' : undefined, marginRight: isMobile ? '1rem' : undefined}}>
                    Organized solo dev work =>{isMobile && <br/>} Team communication with more certainty and less
                    interruption =>{isMobile && <br/>} Next gen customer collaboration
                </CTA>
                {(visiblePage === 'single' || isMobile) && (
                    <SingleTeaser isMobile={isMobile} setVisiblePage={setVisiblePage} />
                )}
                {(visiblePage === 'team' || isMobile) && (
                    <BelowTheFoldDemoTeaser isMobile={isMobile} setVisiblePage={setVisiblePage} />
                )}
                {(visiblePage === 'customer' || isMobile) && (
                    <CustomerTeaser isMobile={isMobile} setVisiblePage={setVisiblePage} />
                )}
            </TextContent>
        </Container>
    );
};

export default AboveTheFold;