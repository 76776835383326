import React from "react";
import tw from "twin.macro";
import Mobile from "../assets/images/mobile.jpg";

const TextContent = tw.div`justify-center items-center mr-2 ml-5`;
const Problem = tw.div`w-11/12 mb-4`;
const Solution = tw.div`w-11/12`;
const ProblemText = tw.div`w-11/12 text-xl md:text-2xl`;

const BelowTheFoldMobile = () => {
    return (
        <TextContent style={{display:"flex", flexDirection:"column"}}>
            <Problem>
                <ProblemText>
                    No install - just go to the normal URL and use our progressive web app.
                </ProblemText>
            </Problem>
            <Solution>
                <img src={Mobile} alt='Mobile' style={{maxHeight: '40rem'}} />
            </Solution>
        </TextContent>
    );
};

export default BelowTheFoldMobile;