import React from "react";
import tw from "twin.macro";
import Inbox from "../assets/images/inbox.png";

const TextContent = tw.div`justify-center items-center mr-2 ml-5`;
const Problem = tw.div`w-11/12 mb-4`;
const Solution = tw.div`w-11/12 mt-4`;
const ProblemText = tw.div`w-11/12 text-xl md:text-2xl`;
const CTAText = tw.div`text-xl md:text-2xl mb-2 pt-8`;
const PrimaryButtonBase = tw.button`px-1 py-1 text-primary-500 border-3 border-primary-500 border-solid hover:bg-primary-500 hover:text-gray-100 focus:outline-none`;
const PrimaryButton = tw(
    PrimaryButtonBase,
)`mt-2 text-2xl font-bold inline-block rounded-xl`;
const primaryButtonUrl = "https://production.uclusion.com/?utm_campaign=team#signup";

const BelowTheFoldNavigation = () => {
    return (
        <TextContent style={{display:"flex", flexDirection:"column"}}>
            <Problem>
                <ProblemText>
                    Use the navigation next button or it's keystroke to jump to what needs your attention - new
                    notifications like below, a job in progress, or unfinished collaboration.
                </ProblemText>
            </Problem>
            <Solution>
                <img src={Inbox} alt='Inbox'
                     style={{maxWidth: '50rem'}} />
                <CTAText>
                    Navigate in the sandbox demo after free <PrimaryButton as="a" href={primaryButtonUrl}>sign up</PrimaryButton>.
                </CTAText>
            </Solution>
        </TextContent>
    );
};

export default BelowTheFoldNavigation;