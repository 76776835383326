import React from "react";
import tw from "twin.macro";
import Unfurl from "../assets/images/slack-unfurl.png";

const TextContent = tw.div`justify-center items-center`;
const Problem = tw.div`w-11/12 mb-5`;
const Solution = tw.div`w-11/12 mt-8`;
const ProblemText = tw.div`w-11/12 text-xl md:text-2xl`;
const CTAText = tw.div`text-xl md:text-2xl mb-2 pt-10`;
const PrimaryButtonBase = tw.button`px-1 py-1 text-primary-500 border-3 border-primary-500 border-solid hover:bg-primary-500 hover:text-gray-100 focus:outline-none`;
const PrimaryButton = tw(
    PrimaryButtonBase,
)`mt-2 text-2xl font-bold inline-block rounded-xl`;
const primaryButtonUrl = "https://production.uclusion.com/?utm_campaign=team#signup";
const LeftAndRight = tw.div`flex justify-between`;
const RightText = tw.div`text-xl md:text-2xl mt-16 ml-6`;

const BelowTheFoldSlack = (properties) => {
    const { isMobile } = properties;
    return (
        <TextContent style={{display:"flex", flexDirection:"column"}}>
            <Problem>
                <ProblemText style={{display: isMobile ? 'none' : undefined}}>
                    Don't choose between sync and async or communication and context.
                </ProblemText>
            </Problem>
            <Solution>
                <LeftAndRight>
                    <img src={Unfurl}  alt='Slack integration'/>
                    <RightText style={{display: isMobile ? 'none' : undefined}}>
                        Integrate with a channel and opening an important approval, question, bug, etc. sends an alarm.
                    </RightText>
                </LeftAndRight>
                <CTAText style={{marginTop: isMobile ? 'unset' : undefined}}>
                    See notifications done right after free <PrimaryButton as="a" href={primaryButtonUrl}>sign up</PrimaryButton>.
                </CTAText>
            </Solution>
        </TextContent>
    );
};

export default BelowTheFoldSlack;