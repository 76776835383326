import React from 'react';
import Header from '../../../shared/SiteHeader';
import AboveTheFold from "../../../shared/AboveTheFold.js";
import tw from "twin.macro";
import Footer from "../../../shared/MiniCenteredFooter";
import BelowTheFold from "../../../shared/BelowTheFold";
import { Roll, Zoom } from "react-swift-reveal";
import Pricing from "../../../shared/Pricing";
import {ArrowDownwardOutlined} from "@material-ui/icons";
import "../../../shared/bounce.css";

const Page = tw.div`h-screen w-full relative bg-blue-200`;
const Container = tw.div`-z-10 bg-blue-200`;
const Scroll = tw.div`mt-24 text-primary-500 text-center`;

function Home() {
    return (
        <Page>
            <Header/>
            <Container>
                <AboveTheFold />
                <Scroll style={{ marginTop: '4rem', marginBottom: '18rem'}}>
                    <div className="bouncer">
                        <ArrowDownwardOutlined/>
                    </div>
                </Scroll>
                <Roll>
                    <BelowTheFold />
                </Roll>
                <Scroll id="pricing" style={{paddingBottom: '2rem'}}>
                    <div className="bouncer"><ArrowDownwardOutlined/></div>
                </Scroll>
                <Zoom>
                    <Pricing />
                </Zoom>
                <Footer/>
            </Container>

        </Page>
    );
}

export default Home;